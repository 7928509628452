@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Monteserrat";
  src: local("Montserrat"), url("./Fonts/Montserrat-Black.ttf") format("ttf");
}

/* In your CSS */
:root {
  --color-primary: #ff9f9f;
  --color-secondary: #ffeaea;
  /* ... */
}
body {
  font-family: Montserrat, sans-serif;
}
table,
th,
td {
  border-collapse: collapse;
}
table {
  margin-bottom: 35px;

  border: solid gray;
}
th {
  background-color: lightgray;
}
.input {
  border: solid gray 2px;
  padding: 5px;
  border-radius: 6px;
}
td {
  border: solid gray;
}
